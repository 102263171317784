// @ts-nocheck
import { merge, omit, forEachInObject, logInfo } from "./util";
import { getSpringfieldCollectUrl, TRACKING_NAME } from "./config";
import applyEventParams from "./hit-types/event";
import applyPageViewParams from "./hit-types/pageview";
import applyStandardParams from "./hit-types/standard";
import applyTimingParams from "./hit-types/timing";

/**
 * @description Gets the document location to be sent to GA dl parameter
 * @param {Object} document The global document object
 * @return A string like this one https://www.example.com/page/#random
 */
const getDocumentLocation = (document) => document.location.toString();

/**
 * @description Like getDocumentLocation, but given a specific path
 * @param {Object} document The global document object
 * @param {String} path The absolute page path, e.g. /path/to/page
 * @return A string like this one https://www.example.com/page/#random
 */
const getVirtualPageviewPath = (document, path, logError, analyticsData) => {
  if (path.substring(0, 1) !== "/") {
    logError(`dp should start with "/", "${path}" given.`, analyticsData);
    path = `/${path}`;
  }
  const location = document.location;
  return `${location.protocol}//${location.hostname}${path}`;
};

const sendAnalytics = (item, document, window, post, logError) => {
  let givenParameters = {};
  try {
    const teliaAnalytics = window.teliaAnalytics;

    givenParameters = merge(item[0], item[1] ? item[1] : {});

    // Parameters specified by the set()-function, they have lower priority than
    // if specified explicitly.
    givenParameters = merge(teliaAnalytics.p, givenParameters);

    // Ensure correct document location is given
    /* istanbul ignore else */
    if (givenParameters.dp) {
      givenParameters.dl = getVirtualPageviewPath(
        document,
        givenParameters.dp,
        logError,
        givenParameters
      );
      givenParameters = omit("dp", givenParameters);
      givenParameters = omit("dh", givenParameters);
    } else if (!givenParameters.dl) {
      givenParameters.dl = getDocumentLocation(document);
    }

    const payloadType = givenParameters.t;

    let applySpecificParams = null;
    if (payloadType === "pageview") {
      applySpecificParams = applyPageViewParams;
    } else if (payloadType === "event") {
      applySpecificParams = applyEventParams;
    } else if (payloadType === "timing") {
      applySpecificParams = applyTimingParams;
    } else if (payloadType === "skip") {
      return;
    } else {
      throw new Error(
        `Unknown type "${payloadType}". Use the named functions on the window.teliaAnalytics object. Payload: ${JSON.stringify(
          givenParameters
        )}`
      );
    }

    // Apply standard parameters for all hit types
    let analyticsData = applyStandardParams(givenParameters, window, document);

    // Apply specific parameters for this hit type
    analyticsData = applySpecificParams(analyticsData, window, document);

    /* istanbul ignore next */
    if (analyticsData.t === "skip") {
      return;
    }

    // Config
    const trackingName = analyticsData.trackingName || TRACKING_NAME;
    const springfieldCollectUrl = getSpringfieldCollectUrl(analyticsData);

    analyticsData.trackingName = null;
    analyticsData.collectDomain = null;
    analyticsData.cookieDomain = null;

    // Clear null-values, null is returned by functions if they cannot calculate the value correctly
    forEachInObject(analyticsData, (value, key) => {
      if (value === null || value === undefined || value === "") {
        analyticsData = omit(key, analyticsData);
        if (value === "") {
          logInfo(`Empty value for key "${key}", dropping.`, analyticsData);
        }
      }
    });

    // Send all to SPRINGFIELD
    const springfieldPayload = merge(analyticsData, { trackingName });

    // The extra request to Springfield (shadowing)
    post(springfieldCollectUrl, springfieldPayload);

    // Debug
    if (localStorage.getItem("ft-analytics-log")) {
      console.log(
        `%c${springfieldPayload.t}\n%c${JSON.stringify(springfieldPayload, null, 4)}`,
        "background-color:#008800;color:#FFFFFF",
        "color:#000000;bakground-color:#FFFFFF"
      );
    }
  } catch (ex) {
    logError(ex.message, givenParameters);
  }
};

export default sendAnalytics;
