import { analytics } from "@telia/b2x-common-frontend-analytics";
import { onceConsented } from "@telia/b2x-cookie-consent";

// single-spa exports
export async function bootstrap(): Promise<void> {
  onceConsented("C0002", "Enable Google Analytics to track events").then(analytics().process);
  return Promise.resolve();
}

export async function mount(): Promise<void> {
  return Promise.resolve();
}

export async function unmount(): Promise<void> {
  return Promise.resolve();
}
