// @ts-nocheck
import { merge, requireParameters, clone, bcEvent } from "../util";

/**
 * @description Applies all parameters that are specific for page views
 * @param  {Object} analyticsData The parameters supplied so far
 * @param  {Object} window The window object
 * @param  {Object} document The document object
 * @returns {String} a clone of analyticsData with additional parameters merged
 */
const applyParams = (analyticsData: any, window: Window) => {
  analyticsData = clone(analyticsData); // since we modify it below

  requireParameters(analyticsData, "pageview", ["dl"]);

  // ----- Timing and performance --------------------------------------------
  // cd16 is used for previous page for SPA, which acts as a trigger not get the timing event
  if (analyticsData.cd54 === "SPA" && !analyticsData.cd16) {
    // Performance metrics must be supplied
    requireParameters(analyticsData, "pageview", ["cm9"]);
    const pageLoadTime = parseInt(`${analyticsData.cm9}`, 10);

    window.setTimeout(() => {
      window.teliaAnalytics.collect({
        t: "timing",
        cd54: "SPA",
        dl: analyticsData.dl,
        plt: pageLoadTime,
        clt: pageLoadTime,
        cm11: analyticsData.cm11 || analyticsData.cm11 === -1 ? analyticsData.cm11 : -1, // Scripts loaded
      });
    }, 1000);

    delete analyticsData.dr;
  }

  // The performance metrics shall not be sent to the pageview hit
  delete analyticsData.cm9;
  delete analyticsData.cm10;
  delete analyticsData.cm11;
  delete analyticsData.cd54;
  // -------------------------------------------------------------------------

  const pageview = bcEvent({ t: "pageview" });
  return merge(analyticsData, pageview);
};

export default applyParams;
