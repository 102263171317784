// @ts-nocheck
import { merge, getCookie, setCookie } from "../util";
import { VERSION } from "../config";

import { getConsentedValue } from "@telia/b2x-cookie-consent";

// ----- Client ID (using cookies) ---------------------------------------------
const cookieName = "teliaAnalyticsCid";
const cookieDays = 180;
//const consentCookieName = "consentLevelCookie";
let clientId = "not loaded";

const uuidv4 = () =>
  "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

const getClientId = (document, analyticsData) => {
  if (clientId === "not loaded") {
    clientId = getCookie(document, cookieName);
  }
  if (clientId === false) {
    clientId = uuidv4();
  }
  setCookie(document, cookieName, clientId, cookieDays, analyticsData.cookieDomain);

  return clientId;
};

export const setClientId = (cid) => {
  clientId = cid;
};

// -----------------------------------------------------------------------------

// ----- Screen information ----------------------------------------------------
/**
 * @description Gets the size of the (entire) screen from the browser. This is the screen size which should be greater or equal to the viewport size
 * @param  {Object} window The window object
 * @returns {String} (width)x(height) in pixels where (width) and (height) is a number, e.g. "1920x1280", or "0x0" if browser does not support this
 */
const getScreenResolution = (window) => {
  const screen = window.screen;
  /* istanbul ignore else */
  if (screen) {
    return `${screen.width}x${screen.height}`;
  } else {
    return null;
  }
};

/**
 * @description Gets the size of the viewport from the browser. This is the viewport size which should be less or equal to the screen size
 * @param  {Object} window The window object
 * @returns {String} (width)x(height) in pixels where (width) and (height) is a number, e.g. "123x456", or "0x0" if browser does not support this
 */
const getViewportSize = (window) => {
  /* istanbul ignore else */
  if (window.innerWidth) {
    return `${window.innerWidth}x${window.innerHeight}`;
  } else {
    return null;
  }
};

const getColorDepth = () => {
  /* istanbul ignore else */
  if (screen && screen.colorDepth) {
    return `${screen.colorDepth}-bits`;
  } else {
    return null;
  }
};
// -----------------------------------------------------------------------------

const validValueOrNull = (value) => (value && value !== "" ? value : null);

/**
 * @description Applies all parameters that are always required for all hit types
 * @param  {Object} analyticsData The parameters supplied so far
 * @param  {Object} window The window object
 * @param  {Object} document The document object
 * @returns {String} a clone of analyticsData with additional parameters merged
 */
const applyParams = (analyticsData, window, document) => {
  // Allow override of document title. This is a temprary solution for sales.
  /* istanbul ignore else */
  if (!analyticsData.dt) {
    analyticsData.dt = validValueOrNull(document.title);
  }

  analyticsData = merge(analyticsData, {
    v: 1,
    cid: getClientId(document, analyticsData),
    sr: getScreenResolution(window),
    vp: getViewportSize(window),
    sd: getColorDepth(),
    dr: validValueOrNull(document.referrer), // Document Referrer
    cd74: `F${VERSION}`,
    cd82: getConsentedValue(),
  });

  return analyticsData;
};
export default applyParams;
