// @ts-nocheck
const bindAutomaticActions = (window, document) => {
  const getAttributeOrDefault = (elem, attributeKey, defaultValue) => {
    if (elem.getAttribute) {
      const value = elem.getAttribute(attributeKey);
      if (value !== null && value !== false && value !== "" && value !== undefined) {
        return value;
      }
    }
    return defaultValue;
  };

  // ----- Automatic click events --------------------------------------------
  const event = (eventCategory, eventAction, eventLabel, extra) => {
    if (eventLabel && eventLabel.replace) {
      eventLabel = eventLabel.replace(/^\s+|\s+$/gm, "");
    }
    if (eventCategory && eventAction && eventLabel) {
      window.teliaAnalytics.event(eventCategory, eventAction, eventLabel, extra);
    }
  };

  const DEFAULT_CATEGORY = "AutoAnalytics";
  const DEFAULT_ACTION = "Click";

  const clickOnElementHandler = (target) => {
    if (target && target.tagName) {
      const tagName = target.tagName;
      const id = target.id ? target.id : null;
      const customLabel = getAttributeOrDefault(target, "data-web-analytics-label", null);

      /* istanbul ignore else */
      if (customLabel !== null) {
        event(
          getAttributeOrDefault(target, "data-web-analytics-category", DEFAULT_CATEGORY),
          getAttributeOrDefault(target, "data-web-analytics-action", DEFAULT_ACTION),
          customLabel,
          { linkid: id }
        );
        return true;
      } else if (window.teliaAnalyticsDisableAuto) {
        return false; // avoid any automagic tracking, but make sure explicit elements with the customLabel still are tracked
      } else if (tagName === "A") {
        // Specific logic for additional event for outbound links
        const href = target.href ? target.href.toString() : null;
        const location = document.location;
        const prefix1 = `${location.protocol}//${location.hostname}`;
        const prefix2 = `//${location.hostname}`;

        // "text" or "id" of links, include href if it exist
        event(DEFAULT_CATEGORY, DEFAULT_ACTION, target.text ? target.text : id, { linkid: id });

        if (href) {
          if (
            (href.substring(0, 2) === "//" && href.substring(0, prefix2.length) !== prefix2) ||
            (href.substring(0, 4) === "http" && href.substring(0, prefix1.length) !== prefix1)
          ) {
            event("Outbound Link", "Click", href);
          } else if (href.indexOf(".pdf") > 0 || href.indexOf(".PDF") > 0) {
            event("File Downloads", "Download", href);
          }
        }
        return true;
      } else if (tagName === "BUTTON") {
        // "text" or "id" of buttons
        event(DEFAULT_CATEGORY, DEFAULT_ACTION, target.textContent ? target.textContent : id, {
          linkid: id,
        });
        return true;
      } else if (tagName === "LABEL") {
        // "for" of labels
        event(DEFAULT_CATEGORY, DEFAULT_ACTION, getAttributeOrDefault(target, "for"));
      } else if (tagName === "INPUT") {
        // "value" of radio buttons and checkboxes
        const inputType = getAttributeOrDefault(target, "type");
        if (inputType === "radio" || inputType === "checkbox") {
          event(DEFAULT_CATEGORY, DEFAULT_ACTION, getAttributeOrDefault(target, "value"));
          return true;
        }
      }
    }
    return false;
  };

  const hasParentNodeWithDontCollectDataTag = (el) => {
    if (el && el.hasAttribute && el.hasAttribute("data-dont-collect")) {
      return true;
    } else if (el && el.parentNode) {
      return hasParentNodeWithDontCollectDataTag(el.parentNode);
    }
    return false;
  };

  document.body.addEventListener("click", (ev) => {
    if (ev.target && !hasParentNodeWithDontCollectDataTag(ev.target)) {
      if (!clickOnElementHandler(ev.target)) {
        clickOnElementHandler(ev.target.parentNode);
      }
    }
  });
  // -------------------------------------------------------------------------

  // ----- Page timing hit ---------------------------------------------------
  const performanceEventHandler = () => {
    window.setTimeout(() => {
      window.teliaAnalytics.collect({ t: "timing", cd54: "Normal" });
    }, 1000);
  };

  /* istanbul ignore else */
  if (document.readyState === "complete") {
    performanceEventHandler();
  } else if (window.addEventListener) {
    window.addEventListener("load", performanceEventHandler, false);
  } else if (window.attachEvent) {
    window.attachEvent("onload", performanceEventHandler);
  }
  // -------------------------------------------------------------------------
};
export default bindAutomaticActions;
