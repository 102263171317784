import { merge, requireParameters, bcEvent } from "../util";
import applyEcomParams from "./ecom";

/**
 * @description Applies all parameters that are specific for events
 * @param  {Object} analyticsData The parameters supplied so far
 * @param  {Object} window The window object
 * @param  {Object} document The document object
 * @returns {String} a clone of analyticsData with additional parameters merged
 */
const applyParams = (analyticsData: any, window: Window, document: Document) => {
  // Enhanced ecommerce event, pa = Product Action
  if (analyticsData.pa) {
    analyticsData = applyEcomParams(analyticsData, window, document);
  }

  requireParameters(analyticsData, "event", ["ec", "ea", "el"]);

  const event = bcEvent({ t: "event" });
  return merge(analyticsData, event);
};

export default applyParams;
