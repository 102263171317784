// @ts-nocheck
import { post } from "./util";
import { VERSION } from "./config";

const main = (window, document, sendData, logError, bindAutomaticActions, logInfo) => {
  if (!window) {
    return;
  }
  // ----- Initiation of teliaAnalytics --------------------------------------

  // Create the window.teliaAnalytics if it is not already created by the
  // page that loaded this script in order to not crash below.
  /* istanbul ignore next */
  if (!window.teliaAnalytics) {
    window.teliaAnalytics = { q: [], p: {} };
    (function (a) {
      const x = "pageview";
      const y = "event";
      a.r = function (b, c) {
        a.q.push([b, c]);
      };
      a[x] = function (b, c, d) {
        a.r({ t: x, dp: b, cm9: c, cd54: "SPA" }, d);
      };
      a[y] = function (b, c, d, e) {
        a.r({ t: y, ec: b, ea: c, el: d }, e);
      };
      a.collect = function (b) {
        a.r(b);
      };
      a.set = function (b, c) {
        a.p[b] = c;
      };
      a.init = undefined;
    })(window.teliaAnalytics);
  }

  // Short hand for usage below.
  const teliaAnalytics = window.teliaAnalytics;

  // ----- Initiation of the service -----------------------------------------

  // Helper function that handles an item that is sent to the service
  const handleQueueItem = (item) => {
    sendData(item, document, window, post, logError);
  };

  const process = () => {
    logInfo("Consent given.", window.teliaAnalytics.p);
    // Prevents error and duplicate requests if the script is loaded multiple
    // times. It should not happen, but if it does...
    if (teliaAnalytics.loaded !== true) {
      teliaAnalytics.loaded = true;

      // Allways send the initial page view first, before processing the
      // queue with possible events and other stuff created before this
      // code was loaded.
      handleQueueItem([{ t: "pageview", cd54: "Normal" }]);

      const queuedEvents = teliaAnalytics.q;

      // Hook up to get all pushes to the queue immediately, no need to
      // poll or observe the queue this way.
      teliaAnalytics.q = { push: handleQueueItem };

      // Parse any existing stuff that was created before this script was
      // loaded. Do this after the initial page view to get correct
      // entrance / landing info.
      queuedEvents.forEach((value) => {
        handleQueueItem(value);
      });

      // This binds to document and sends automatic events and pageviews
      // such as the performance event and other automated events.
      bindAutomaticActions(window, document);
    }
  };

  teliaAnalytics.version = VERSION;
  teliaAnalytics.process = process;

  // Return the teliaAnalytics API. It is also exposed using
  // window.teliaAnalytics which is the preferred way to access the API.
  return teliaAnalytics;
};

export default main;
