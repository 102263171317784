// @ts-nocheck
import { getLogUrl } from "./config";

type Obj = Record<string, number | string>;

type LogLevel = "ERROR" | "INFO";

export const bcEvent = (obj: any) => {
  const BCSessionIDSet = window.localStorage && window.localStorage.getItem("BCSessionID");
  const BCSessionID = BCSessionIDSet ? window.localStorage.getItem("BCSessionID") : "";

  const added = {
    cd15: BCSessionID,
  };

  return BCSessionID ? merge(obj, added) : obj;
};

type Callback = (value: any, key: string) => void;

export const forEachInObject = (object: Obj, callback: Callback) => {
  if (object != null) {
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        callback(object[key], key);
      }
    }
  }
};

export const merge = (a: Obj, b: Obj) => {
  const newObject: Obj = {};

  forEachInObject(a, (value, key) => {
    newObject[key] = value;
  });

  forEachInObject(b, (value, key) => {
    newObject[key] = value;
  });
  return newObject;
};

export const clone = (a: Obj) => {
  const newObject: Obj = {};
  forEachInObject(a, (value, key) => {
    newObject[key] = value;
  });
  return newObject;
};

export const omit = (keyToOmit: string, object: Obj) => {
  const newObject: Obj = {};
  forEachInObject(object, (value, key) => {
    if (keyToOmit !== key) {
      newObject[key] = value;
    }
  });
  return newObject;
};

export const requireParameters = (
  analyticsData: Obj,
  nameOfFunction: string,
  listOfRequiredParams: string[]
) => {
  listOfRequiredParams.forEach((key) => {
    if (!analyticsData[key] && analyticsData[key] !== 0) {
      throw new Error(
        `"${nameOfFunction}" must have the key "${key}" set! Given: ${JSON.stringify(
          analyticsData
        )}`
      );
    }
  });
};

/* istanbul ignore next */
export const logHelper = (msg: string, analyticsData: Obj, level: LogLevel) => {
  post(getLogUrl(analyticsData), {
    message: msg,
    title: "Common frontend analytics",
    logLevel: level,
  });
};

/* istanbul ignore next */
export const logError = (msg: string, analyticsData: Obj) => {
  /* istanbul ignore next */
  if (window) {
    window.console.error(`[ERROR] Common frontend analytics: ${msg}`);
  }
  logHelper(msg, analyticsData, "ERROR");
};

/* istanbul ignore next */
export const logInfo = (msg: string, analyticsData: Obj) => {
  /* istanbul ignore next */
  if (window) {
    window.console.log(`[INFO] Common frontend analytics: ${msg}`);
  }
  /* istanbul ignore next */
  logHelper(msg, analyticsData, "INFO");
};

// /**
//  * @description Gets the number of scripts on the page, based on the number of script tags with src attribute. This is not perfect. Please improve if you have a better way to do it.
//  * @param  {Object} document The global document object
//  * @returns {Number} The number of scripts loaded according to the description above
//  */
// const getScriptCount = (document) => {
//     let scriptCount = 0;
//     const scriptElements = document.getElementsByTagName('script');
//     for(let i = 0; i < scriptElements.length; i = i + 1) {
//         const element = scriptElements[i];
//         if(element.src) {
//             scriptCount = scriptCount + 1;
//             console.log(`Script: ${element.src}`);
//         }
//     }
//     return scriptCount;
// };

export const sendPerformanceEvent = (window: Window, analyticsData: Obj) => {
  const pageLoadTime = analyticsData.plt as number;
  const domContentLoaded = analyticsData.clt as number;
  const pageType = analyticsData.cd54 as number;

  if (
    pageLoadTime >= 0 &&
    domContentLoaded >= 0 &&
    pageLoadTime <= 60000 &&
    domContentLoaded <= 60000 &&
    pageType
  ) {
    window.teliaAnalytics.event("Performance", "PageLoaded", analyticsData.dl, {
      cm9: pageLoadTime, // Performance.Page Load Time
      cm10: domContentLoaded, // Performance.DOM Content Loaded
      cm11: analyticsData.cm11, // Performance.Script Count
      cd54: pageType, // Performance.Page Type
    });
  } else {
    logError(
      `Invalid performance data given for pageview hit: ${pageLoadTime}, ${domContentLoaded}, ${pageType}`,
      analyticsData
    );
  }
};

export const setCookie = (
  document: Document,
  cname: string,
  cvalue: string,
  exdays: number,
  cookieDomain: string
) => {
  try {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    if (cookieDomain) {
      document.cookie = `${cname}=${cvalue};${expires};path=/;domain=${cookieDomain}`;
    } else {
      document.cookie = `${cname}=${cvalue};${expires};path=/`;
    }
  } catch (e) {
    /* istanbul ignore next */
    console.error("Unable to set teliaAnalyticsCid");
  }
};

export const getCookie = (document: Document, cname: string) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");

  for (let i = 0; i < ca.length; i = i + 1) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return false;
};

export const getQueryMap = (window: Window) => {
  const map = {} as Obj;
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  vars.forEach((part) => {
    const pair = part.split("=");
    if (pair.length > 1) {
      map[pair[0]] = decodeURIComponent(pair[1]);
    }
  });
  return map;
};

/**
 * @description Performs a REST POST to a given URL with the given data with content-type set to json.
 * @param {String} url The URL to POST to.
 * @param {Object} data The data to post as an object.
 * @memberof rest
 */
export const post = (url: string, payload: any) => {
  if (!window.teliaAnalyticsDeprecationWarning) {
    console.warn("[deprecated] window.teliaAnalytics is no longer in use.");
  }

  window.teliaAnalyticsDeprecationWarning = true;
};
