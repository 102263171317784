// @ts-nocheck
import { merge, requireParameters, forEachInObject } from "../util";

import { ANALYTICS_PRODUCT_PARAM_PATTERN } from "../config";

/**
 * @description Extract all unique product indexes in the analytics data. See https://diva.teliasonera.net/confluence/display/DCCO/Parameter+Tables
 * @param  {Object} analyticsData The parameters to scan
 * @returns {Object} an object containing all indexes as keys
 */
const findProductIndexes = (analyticsData: any) => {
  const indexes = {};
  forEachInObject(analyticsData, function (value, key) {
    const keyMatch = key.match(ANALYTICS_PRODUCT_PARAM_PATTERN);
    if (keyMatch) {
      const index = keyMatch.groups ? keyMatch.groups.index : keyMatch[1]; // Get named group or result in position 1 (will be captured group)
      indexes[index] = true;
    }
  });
  return indexes;
};

/**
 * @description Applies all parameters that are specific for ecom events
 * @param  {Object} analyticsData The parameters supplied so far
 * @param  {Object} window The window object
 * @param  {Object} document The document object
 * @returns {String} a clone of analyticsData with additional parameters merged
 */
const applyParams = (analyticsData, window = window, document = document) => {
  let requiredParameters = [];
  switch (analyticsData.pa) {
    case "detail":
    case "click":
    case "add":
    case "remove":
    case "cart":
      requiredParameters = [
        "ta", // Affiliation
        //'tr', // Revenue
        //'tt', // Tax
        "cu", // Currency Code
      ];
      break;

    case "purchase":
    case "refund":
      requiredParameters = [
        "ta", // Affiliation
        //'tr', // Revenue
        //'tt', // Tax
        "cu", // Currency Code

        // Additional
        "ti", // Transaction ID
        "ts", // Shipping
      ];
      break;

    case "checkout":
      requiredParameters = [
        "ta", // Affiliation
        //'tr', // Revenue
        //'tt', // Tax
        "cu", // Currency Code

        // Additional
        "cos", // Checkout Step
      ];
      break;
    default:
      throw new Error("Invalid pa value for ecom event.");
  }
  requireParameters(analyticsData, `event (ecom ${analyticsData.pa})`, requiredParameters);

  // If the product index exist, then require all of these for that index
  // See https://diva.teliasonera.net/confluence/display/DCCO/Parameter+Tables
  forEachInObject(findProductIndexes(analyticsData), function (dummy, index) {
    requireParameters(analyticsData, `event (ecom productindex ${index})`, [
      `pr${index}id`,
      `pr${index}nm`,
      `pr${index}br`,
      `pr${index}ca`,
      `pr${index}va`,
      //`pr${index}pr`,
      `pr${index}qt`,
    ]);
  });

  return merge(analyticsData, {
    t: "event",
  });
};
export default applyParams;
