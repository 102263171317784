// @ts-nocheck
import { merge } from "../util";

/**
 * @description Gets all the timing metrics as calculated by the original GA script
 * @param  {Object} window The window object
 * @returns {Object} A map with correct Measurement Protocol keys and values
 */
const getTimingData = (window) => {
  // Analytics.js reverse engineering:

  let timing = window.performance || window.webkitPerformance;
  timing = timing && timing.timing;

  if (!timing) {
    return {};
  }

  const navigationStart = timing.navigationStart;

  return {
    plt: timing.loadEventStart - navigationStart, // timing.loadEventEnd - timing.navigationStart | timing.loadEventStart - navigationStart,
    dns: timing.domainLookupEnd - timing.domainLookupStart,
    tcp: timing.connectEnd - timing.connectStart,
    srt: timing.responseStart - timing.requestStart,
    pdt: timing.responseEnd - timing.responseStart,
    rrt: timing.fetchStart - navigationStart,
    dit: timing.domInteractive - navigationStart,
    clt: timing.domContentLoadedEventStart - navigationStart, // timing.domContentLoadedEventEnd - timing.navigationStart | timing.domContentLoadedEventStart - navigationStart
  };
};

/**
 * @description Applies all parameters that are specific for timings
 * @param  {Object} analyticsData The parameters supplied so far
 * @param  {Object} window The window object
 * @param  {Object} document The document object
 * @returns {String} a clone of analyticsData with additional parameters merged
 */
const applyParams = (analyticsData, window, document = document) => {
  analyticsData = merge(getTimingData(window), analyticsData);
  if (
    analyticsData.plt >= 0 &&
    analyticsData.clt >= 0 &&
    analyticsData.plt <= 60000 &&
    analyticsData.clt <= 60000
  ) {
    return merge(analyticsData, {
      t: "timing",
    });
  } else {
    return {
      t: "skip",
    };
  }
};

export default applyParams;
