// @ts-nocheck
// Relative path to where to log errors
const LOG_PATH = "/.api/frontend-logger-service/v1/log";

export const ANALYTICS_PRODUCT_PARAM_PATTERN = /^pr(?<index>\d+)[a-zA-Z]+\d*/;

export const VERSION = `${process.env.VERSION}-teliasaurus`;

// The name that BE will translate into correct tid-parameter depending on environment
export const TRACKING_NAME = "webAnalytics";

export const CONSENT_LABEL = "google-analytics";

const getBaseUrl = (analyticsData) =>
  analyticsData && analyticsData.collectDomain ? `https://${analyticsData.collectDomain}` : "";

export const getLogUrl = (analyticsData) => getBaseUrl(analyticsData) + LOG_PATH;

export const getSpringfieldCollectUrl = (analyticsData) =>
  `${getBaseUrl(analyticsData)}/.api/web-analytics/v1/collect`;
